// React
import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";

// MUI Components
import { Container } from "@material-ui/core";
import SummaryBar from "components/SummaryBar";

// Styles
import { useStyles } from "./styles";

import * as userUtil from "util/userUtil";

//Components
import LoginsData from "components/LoginsData";

export default function AdminScreen(props) {
  const classes = useStyles();

  useEffect(() => {
    window.gtag("event", "page_view", {
      page_title: "Admin Screen",
      page_path: `${window.location.pathname}${window.location.hash}`,
      path_location: window.location.href,
      send_to: `${process.env.REACT_APP_GA}`
    });
  }, []);

  if (props.me?.email && !userUtil.isEmployee(props.me) && !userUtil.isAdmin(props.me)) {
    return <Navigate to={{ pathname: "/403" }} replace />;
  }


  return (
    <React.Fragment>
      <SummaryBar expanded={false} hideExpand={true} title="Admin Panel" />
      <Container maxWidth="md" className={classes.container}>
        <React.Fragment>
            
            <LoginsData></LoginsData>
           
        </React.Fragment>

      </Container>
    </React.Fragment>
  );
}
