/**
 * @description AdminApi Module. This will have all the API's needed
 * to interact with the backend API's for the Admin data
 * @module apis/AdminApi
 * @since 1.0.0
 * @author David Ganz <davidg@aaisonline.com>
 * @public
 */

import { API } from "aws-amplify";


export async function listLogins(days=40) {

  const path = `/admin/listLogins/${days}`;
  try {
    return await API.get("ADMIN", path);
  } catch (error) {
    console.log(error);
  }
}