import React, { useState, useEffect } from "react";
import { useStyles } from "./styles";

import * as AdminApi from "apis/AdminApi";
import LinearProgress from "@material-ui/core/LinearProgress";
import Input from '@mui/material/Input';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

const displayStates = {raw: 'raw', summary: 'summary'};
export default function LoginsData(props) {
    const classes = useStyles();
    const [allData, setAllData] = useState();
    const [loading, setLoading] = useState(true);
    const [inputValue, setInputValue] = useState(7);
    const [debouncedValue, setDebouncedValue] = useState();
    const [summaryData, setSummaryData] = useState();
    const [rawData, setRawData] = useState();
    const [displayState, setDisplayState] = useState(displayStates.raw);

    useEffect(() => {
        getHistory();
      }, []);

    useEffect(() => {
      if(displayState === displayStates.summary){
        setAllData(summaryData);
      }
      else{
        setAllData(rawData);
      }
        
    }, [displayState]);

    useEffect(() => {
      let i = -1;
      if(!summaryData || !rawData){
        return;
      }
      const data = (displayState === displayStates.summary) ? summaryData : rawData;
      const temp = data.map(element => {
        i = i + 1;
        return <li key={i} name={`login-element-${i}`} id={`login-element-${i}`}>{element}</li>;
      });
      setAllData( <ul name="logins-table" id="logins-table" >{temp}</ul>);
      setLoading(false);
    }, [summaryData, rawData, displayState]);

    useEffect(() => {
      const timeoutId = setTimeout(() => {
        setDebouncedValue(inputValue);
        getHistory();
      }, 500); // Delay in milliseconds
  
      return () => clearTimeout(timeoutId);
    }, [inputValue]);

    const handleChangeRadio = (event) => {
        setDisplayState(event.target.value);
    };
    const handleChange = (event) => {
      try{
        let thisEvent = event.target.value;
        if(thisEvent.length === 0){
          thisEvent = 0;
        }
        const thisInput = parseInt(thisEvent);
        if(thisInput || thisInput === 0 ){
          setInputValue(thisInput);
        }
      }catch (error) {
        console.log(error);
      }
    };

    async function getHistory() {
        
        setLoading(true);
        const data = await AdminApi.listLogins(inputValue);
        if(data){
          setSummaryData(data.summary);
          setRawData(data.raw);
        }
    }
    
     
      

  return (
    <div className={classes.tabsContainer} key={0}>
    {loading && <LinearProgress />}
    {!loading && (
        <div>
           
          <FormControl>
            <FormLabel id="recent-logins-row-radio-buttons-group-label">Recent Logins</FormLabel>
            <RadioGroup
              row
              aria-labelledby="recent-logins-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={displayState}
              onChange={handleChangeRadio}
            >
              <FormControlLabel value="raw" id="raw-radio-button" name="raw-radio-button" control={<Radio />} label="Raw" />
              <FormControlLabel value="summary" id="summary-radio-button" name="summary-radio-button" control={<Radio />} label="Summary" />
              
            </RadioGroup>
        </FormControl>:  <br></br>
          Days to look back: &nbsp;&nbsp;&nbsp;
          <Input
            value={inputValue}
            variant="outlined"
            type="text"
            onChange={handleChange}
            id="days-select"
            name="days-select"
            data-test="days-select"
            label="days-select"
            
          ></Input>
          {allData}            
        </div>
    )}
    </div>
    
  );

}