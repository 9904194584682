// Packages
import React, { useState, useEffect } from "react";

// MUI Components
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import CardHeader from "@material-ui/core/CardHeader";
import Paper from "@material-ui/core/Paper";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import LinearProgress from "@material-ui/core/LinearProgress";
import ZipStatus from "./ZipStatus";


// Components
import DownloadMaterialsDialog from "components/DownloadMaterialsDialog";

// Services
import * as AssetApi from "apis/AssetApi";

// Data
import PACKAGE_TYPES from "enums/PACKAGE_TYPES";
import LINES from "enums/LINES";
import STATES from "enums/STATES";


// Styles
import { useStyles } from "./styles";

export default function DownloadHistoryCard(props) {
  const classes = useStyles();
  const [manifestId, setManifestId] = useState();
  const [manifests, setManifests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [allData, setAllData] = useState();

  useEffect(() => {
    getHistory();
  }, [props.lineKey]);

  useEffect(() => {
    getHistory(true);
  }, [props.stateKey]);
  

  async function getHistory(skip=false) {
    let data = allData;
    if(skip === false){
      setLoading(true);
      data = await AssetApi.getUserManifestMetaData(
        props.userId,
        props.lineKey
      );
      setAllData(data);
    }
    if (Array.isArray(data)) {
      if(props.browseScreen){
        if(props.stateKey && props.stateKey.length >= 1){
          const validData = [];
          const statesKeys = props.stateKey.map(
            (state) => STATES[state]?.key
          );          
          data?.map((datum) => {
            const theseSates = datum?.request?.states;
            for(const x in theseSates){
              if(statesKeys.includes(theseSates[x])){
                validData.push(datum);
              }
            }
            
          });       
          setManifests(validData.slice(0, 10));
        }else{
          setManifests(data.slice(0, 10));
        }
        setLoading(false);
      }
      else{
        setManifests(data);
        setLoading(false);
      }
    }
  }

  function trackDownload(tempManifestId) {
    window.gtag("event", "package.download.redownload", {
      event_category: props.lineKey,
      event_label: tempManifestId,
      value: 1,
    });
  }

  function getSubheader(request) {
    const packageTypes = request?.packageTypes;
    if (packageTypes && !Array.isArray(packageTypes)) {
      return packageTypes;
    }
    //return "Custom Package";
    if(request.product && !request.products){
      if(!Array.isArray(request.product)){
        request.products = [request.product];
      }
      else{
        request.products = request.product;
      }
    }
    let products = request?.products.map(
      (line) => LINES[line]?.key
    );
    if(products.length === 0 || products.length > 1){
      return 'Multline';
    }
    products = products.sort();
    products = products.join(", ");
    //eslint-disable-next-line no-useless-concat
    products = products.replace(/,([^,]*)$/, " and " + "$1");
    return products;
    //manifest.request.states.sort().join(", ")
  }
  function getJurisdictions(jurisdiction){
    let states = jurisdiction;

    if(states.length === 0 || states.length > 1){
      return 'Multistate';
    }
    states = states.sort();
    states = states.join(", ");
    //eslint-disable-next-line no-useless-concat
    states = states.replace(/,([^,]*)$/, " and " + "$1");
    return states;
  }

  function getList() {
    const dateTimeFormat = new Intl.DateTimeFormat('en-US', {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      //hour: "numeric",
      //minute: "numeric"
      });
    return (
      <List className={classes.list} disablePadding={true}>
        {manifests.map((manifest) => (
          <ListItem
            key={manifest.manifestId}
            divider={true}
            disableGutters={true}
          >
            < ZipStatus manifest={manifest} manifestId={manifest.manifestId} setManifestId={setManifestId} trackDownload={trackDownload} getSubheader={getSubheader} getJurisdictions={getJurisdictions} dateTimeFormat={dateTimeFormat}/>
            
          </ListItem>
        ))}
      </List>
    );
  }

  return (
    <React.Fragment>
      <Grid item xs={12} className={classes.grid}>
        <Card className={classes.card}>
          {!props.browseScreen && <CardHeader
            className={classes.cardHeader}
            title={`My Recent ${props.lineKey} Downloads`}
            subheader={`We save your downloads so you don't have to`}
          />}
          {loading && <LinearProgress />}
          <CardContent className={classes.cardContent}>
            <Paper className={classes.paper} elevation={0}>
              {!loading && manifests.length === 0 && (
                <Typography>No recent downloads</Typography>
              )}
              {!loading && manifests.length !== 0 && getList()}
            </Paper>
          </CardContent>
        </Card>
        {manifestId && (
          <DownloadMaterialsDialog
            manifestId={manifestId}
            handleClose={() => setManifestId(null)}
          ></DownloadMaterialsDialog>
        )}
      </Grid>
    </React.Fragment>
  );
}
