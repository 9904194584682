// Packages
import React, { useEffect, useState } from "react";
import { withAuthenticator } from "@aws-amplify/ui-react";
import { HashRouter, Route, Routes } from "react-router-dom";
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";
import { Auth, Analytics, Amplify } from "aws-amplify";

// MUI Components
import Dialog from "@material-ui/core/Dialog";

// MUI Icons
import HomeIcon from "@material-ui/icons/Home";
import FilterNoneIcon from "@material-ui/icons/FilterNone";
import AccountTreeIcon from "@material-ui/icons/AccountTree";
import SettingsIcon from "@material-ui/icons/Settings";
import SignOutIcon from "@material-ui/icons/ExitToApp";
import LiveHelpIcon from "@material-ui/icons/LiveHelp";

// Components
import Sidebar from "components/Sidebar";
import Topbar from "components/Topbar";
import SimpleBottomNavigation from "components/BottomNavigation";
import TermsModal from "components/TermsModal";
import AITermsModal from "components/AITermsModal";
import Footer from "components/Footer";
import IdleModal from "components/IdleModal";

// Screens
import UsersScreen from "screens/UsersScreen";
import UserScreen from "screens/UserScreen";
import ScreenNotFoundScreen from "screens/ScreenNotFoundScreen";
import OrgScreen from "screens/OrgScreen";
import OrgsScreen from "screens/OrgsScreen";
import LineScreen from "screens/LineScreen";
import NotificationsScreen from "screens/NotificationsScreen";
import RiskAwarenessScreen from "screens/RiskAwarenessScreen";
import HomeScreen from "screens/HomeScreen";
import EmployeeDashboardScreen from "screens/EmployeeDashboardScreen";
import AccessDeniedScreen from "screens/AccessDeniedScreen";
import BrowseScreen from "screens/BrowseScreen";
import HealthStatusCheck from "./screens/HealthStatusCheckScreen/HealthCheckStatus";
import ProductScreen from "screens/ProductScreen";
import AdminScreen from "screens/AdminScreen";

// Context
import MeContext from "contexts/MeContext";
import ProductsContext from "contexts/ProductsContext";
import LandingPagesContext from "contexts/LandingPagesContext";

// Hooks
import useDevice, { constants } from "hooks/useDevice";

// Services
import * as UserApi from "apis/UserApi";
import * as ProductApi from "apis/ProductApi";
import * as LandingPageApi from "apis/LandingPageApi";

// Config
import { AMPLIFY_CONFIG } from "./config/amplifyConfig";

// Styles
import { AaisTheme } from "styles/AaisTheme";
import "@aws-amplify/ui-react/styles.css";
import { useStyles } from "./styles";
import {
  ANALYTICS_EVENT_CONFIG,
  ANALYTICS_PAGE_VIEW_CONFIG,
} from "./config/analyticsConfig";
import SignInHeader from "./components/SignInHeader";
import FaqScreen from "screens/FaqScreen";

Amplify.configure(AMPLIFY_CONFIG);
Analytics.autoTrack("pageView", ANALYTICS_PAGE_VIEW_CONFIG);
Analytics.autoTrack("event", ANALYTICS_EVENT_CONFIG);

let signedOutTop;

function App({ signOut }) {
  const classes = useStyles();
  const device = useDevice();
  const [me, setMe] = useState(UserApi.getMe(true));
  const [landingPages, setLandingPages] = useState(LandingPageApi.getAll());
  const [products, setProducts] = useState(ProductApi.getAll());
  const [showTerms, setShowTerms] = useState(false);
  const [showAITerms, setAIShowTerms] = useState(false);
  const [toggleToRerender, setToggleToRerender] = useState(false);
  const [signedOut, setSignedOut] = useState(false);
  const [productData, setProductData] = useState();

  useEffect(() => {
    async function fetchMyUser() {
      await UserApi.getMe().then((user) => {
        setMe(user);
        if (!user.termsAccepted) setShowTerms(true);
        const oneYearAgo = new Date();
        oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);
        if ( !user.aiTermsAccepted ||  new Date(user.aiTermsAccepted).getTime() < oneYearAgo.getTime() ) setAIShowTerms(true);
      });
    }
    async function fetchLandingPages() {
      try{
        const tempLandingPages = await LandingPageApi.getAll();
        setLandingPages(tempLandingPages);
      }catch(error) {
        console.log('setLandingPages error: ', error);
      }
    }
    async function fetchProducts() {
      try{
        const apiAllProducts = await ProductApi.getAll();//getProducts(theseLandingPageProducts);
        setProducts(apiAllProducts);
      }catch(error) {
        console.log('fetchProducts error: ', error);
      }
      
    }
    fetchMyUser();
    fetchLandingPages();
    fetchProducts(); 
  }, []);

  useEffect(() => {
    async function fetchMyUser() {
      if(toggleToRerender){
        await UserApi.getMe().then((newUser) => {
          setMe(newUser);
        });
        setToggleToRerender(false);
      }
    }
    fetchMyUser();
  }, [toggleToRerender]);


  
  useEffect(() => {
    signedOutTop = signedOut;
  }, [signedOut]);

  useEffect(() => {
    if (me) {
      window.gtag("config", `${process.env.REACT_APP_GA}`, {
        user_id: me.userId,
      });
      window.gtag("set", { user_id: me.userId });
    }
  }, [me]);

  async function acceptTerms() {
    setShowTerms(false);
    const updatedMe = await UserApi.acceptTerms(me.userId);
    setMe(updatedMe);
  }

  async function acceptAITerms() {
    setAIShowTerms(false);
    const updatedMe = await UserApi.acceptAITerms(me.userId);
    setMe(updatedMe);
  }

  const menuItems = [
    {
      text: "Home",
      icon: <HomeIcon />,
      path: "/#",
      nav: 0,
    },
    {
      text: "Browse Products",
      icon: <FilterNoneIcon />,
      path: "/#/browse",
      nav: 1,
    },
    {
      text: "My Organizations",
      icon: <AccountTreeIcon />,
      path: "/#/orgs",
      nav: 2,
    },
    {
      text: "Settings",
      icon: <SettingsIcon />,
      path: `/#/users`,
      nav: 3,
    },
    {
      text: "FAQ",
      icon: <LiveHelpIcon />,
      path: `/#/faq`,
      nav: 4,
    },
    {
      text: "Admin",
      icon: <SettingsIcon />,
      path: "/#/admin",
      nav: 5,
    },
    {
      text: "Sign Out",
      icon: <SignOutIcon />,
      path: "/",
      nav: 6,
    },
  ];

  const getRoutes = () => {
    return (
      <Routes>
        <Route path="/" element={<HomeScreen setProductData={setProductData}/>} />
        <Route path="/orgs" element={<OrgsScreen />} />
        <Route path="/orgs/:orgId" element={<OrgScreen />} />
        <Route path="/employees" element={<EmployeeDashboardScreen />} />
        <Route path="/risk" element={<RiskAwarenessScreen />} />
        <Route path="/notifications" element={<NotificationsScreen />} />
        <Route path="/lines/:lineKey" element={<LineScreen />} />
        <Route path="/users" element={<UsersScreen />} />
        <Route path="/search" element={<BrowseScreen />} />
        <Route path="/browse" element={<BrowseScreen />} />
        <Route path="/users/:userId" element={<UserScreen setToggleToRerenderTop={setToggleToRerender}/>} />
        <Route path="/403" element={<AccessDeniedScreen />} />
        <Route path="/faq" element={<FaqScreen />} />
        <Route path="/health-check" element={<HealthStatusCheck />} />
        <Route element={<ScreenNotFoundScreen />} />
        <Route path="/product" element={<ProductScreen productData={productData} setProductData={setProductData}/>}/>
        <Route path="/admin" element={<AdminScreen me={me}/>}/>
      </Routes>
    );
  };

  return (
    <MuiThemeProvider theme={createTheme(AaisTheme)}>
      <HashRouter>
        <MeContext.Provider value={me}>
          <LandingPagesContext.Provider value={landingPages}>
            <ProductsContext.Provider value={products}>
              <div className={classes.root}>
                <Topbar signOut={signOut} setProductData={setProductData}></Topbar>
                <div className={classes.content}>
                  <IdleModal signOut={signOut} setSignedOut={setSignedOut}></IdleModal>
                  <div className={classes.routes}>
                    {getRoutes()}
                  </div>
                </div>
                <Footer/>
              </div>
            </ProductsContext.Provider>
          </LandingPagesContext.Provider>
        </MeContext.Provider>
        <Dialog
          open={showAITerms}
          scroll="paper"
          disableEscapeKeyDown={true}
          disableBackdropClick={true}
          onClose={() => setAIShowTerms(false)}          
        >
          <AITermsModal handleClose={acceptAITerms} />
        </Dialog>

        <Dialog
          open={showTerms}
          scroll="paper"
          disableEscapeKeyDown={true}
          disableBackdropClick={true}
          onClose={() => setShowTerms(false)}
        >
          <TermsModal handleClose={acceptTerms} />
        </Dialog>

      </HashRouter>
    </MuiThemeProvider>
  );
}

const components = {
  SignIn: {
    Header: () =>
      SignInHeader({
        federatedSignIn: () => Auth.federatedSignIn({ provider: "Okta" }),
        signedOut: signedOutTop,
      }),
  },
};

export default withAuthenticator(App, { hideSignUp: true, components });
